import { batch } from "react-redux";
import { saveBoardCondition } from "../store/reducers/boardConditionSlice";
import { saveGameData, saveNewHand } from "../store/reducers/gameDataSlice";
import { saveUsersTimes } from "../store/reducers/usersTimesSlice";
import { soundPlayByName } from "../services/sounds";

const newHandController = res => (dispatch, getState) => {
    const {
        settingsState: { isAnimationEnabled },
        gameDataState,
        gameDataState: {
            cardsToBoard,
            cardsToLastBeat,
            newHandAfterStep,
            player: { ...playerData },
            opponent: { ...opponentData },
            player: { cards: playerCards },
            board: { cards: stateBoardCards },
            opponent: { cards: opponentCards }
        },
        boardConditionState: { showPlaceholder, isActivePlaceholder }
    } = getState();

    const { newHand, lastBeat, boardCards, isPlayerPlayed, player: resPlayer, opponent: resOpponent } = res;

    const updateTimers = () => {
        dispatch(
            saveUsersTimes({
                player: { stepTime: resPlayer.stepTime, gameTime: resPlayer.gameTime },
                opponent: { stepTime: resOpponent.stepTime, gameTime: resOpponent.gameTime }
            })
        );
    };

    const isLastBeat = lastBeat?.cards.includes(res.move.cardId);
    const stepResultMainData = {
        ...gameDataState,
        ...res
    };

    let stepResultData;

    if (isAnimationEnabled) {
        stepResultData = {
            ...stepResultMainData,
            newHandAfterStep: newHand,
            newHand: null,
            player: {
                ...playerData,
                ...resPlayer,
                cards: playerCards,
                cardsAfterStep: null,
                cardsAfterNewHand: newHand.playerCards
            },
            opponent: {
                ...opponentData,
                ...resOpponent,
                cardsAfterStep: null,
                cards: opponentCards,
                cardsAfterNewHand: newHand.opponentCards
            },
            board: { cards: stateBoardCards, cardsAfterStep: boardCards, cardsAfterNewHand: boardCards },
            newData: res
        };

        stepResultData.cardsToLastBeat = isLastBeat ? lastBeat.cards : [];
        stepResultData.cardsToBoard = !isLastBeat ? [res.move.cardId] : [];
        stepResultData.opponent.cards = !isPlayerPlayed ? [res.move.cardId] : opponentCards;

        if (isLastBeat) {
            stepResultData = {
                ...stepResultData,
                lastBeat: {
                    ...lastBeat,
                    cardsAfterStep: [...(lastBeat.cards || [])],
                    cards: []
                }
            };
        }
    } else {
        stepResultData = {
            ...stepResultMainData,
            player: {
                ...playerData,
                ...resPlayer,
                cards: []
            },
            opponent: {
                ...opponentData,
                ...resOpponent,
                cards: []
            },
            board: {
                cards: isLastBeat
                    ? stateBoardCards.filter(i => !lastBeat.cards.includes(i))
                    : [...stateBoardCards, ...(stateBoardCards?.includes(res.move.cardId) ? [] : [res.move.cardId])]
            },
            newData: res
        };
    }

    batch(() => {
        if (
            isAnimationEnabled &&
            !isLastBeat &&
            !cardsToBoard.length &&
            !cardsToLastBeat.length &&
            (!showPlaceholder || !isActivePlaceholder) &&
            !newHandAfterStep
        ) {
            dispatch(saveBoardCondition({ showPlaceholder: true, isActivePlaceholder: true }));
        } else if (!isAnimationEnabled && !isLastBeat && (showPlaceholder || isActivePlaceholder)) {
            dispatch(saveBoardCondition({ showPlaceholder: false, isActivePlaceholder: false }));
        }
        dispatch(saveNewHand(stepResultData));
        updateTimers();
    });

    if (!isAnimationEnabled) {
        setTimeout(() => {
            dispatch(
                saveGameData({
                    ...stepResultMainData,
                    player: {
                        ...playerData,
                        ...resPlayer,
                        cards: newHand.playerCards
                    },
                    opponent: {
                        ...opponentData,
                        ...resOpponent,
                        cards: newHand.opponentCards
                    },
                    board: { cards: boardCards }
                })
            );
            soundPlayByName("playerDealing");
        }, 1300);
    }
};

export default newHandController;
