import { createSelector } from "reselect";

export const getAppData = ({ settingsState, initialState, popupTypesState, lowConnectionState }) => {
    const { popupType } = popupTypesState;
    const { isSoundOn, loading, themes } = settingsState;
    const isGuest = initialState.gameInitialState?.isGuest;
    const isConnectionLow = lowConnectionState?.isConnectionLow;
    const gameConfiguration = initialState?.gameInitialState?.gameConfiguration;

    return {
        themes,
        isGuest,
        popupType,
        isSoundOn,
        isConnectionLow,
        gameConfiguration,
        settingsLoading: loading
    };
};

export const getGameContainerData = ({
    initialState,
    gameDataState,
    settingsState,
    dealingStepsState,
    dataReceivedState
}) => {
    const isDemo = initialState.gameInitialState?.gameConfiguration?.isDemo;
    const { step1, step2, step3, step4 } = dealingStepsState?.steps;
    const { popups, isPlayerTurn, finishStatus } = gameDataState;
    const { isAnimationEnabled, currentTheme } = settingsState;
    const isGuest = initialState.gameInitialState.isGuest;
    const { dataReceived } = dataReceivedState;

    return {
        doubleStakeStatus: popups?.doubleStake?.status,
        isAnimationEnabled,
        currentTheme,
        dataReceived,
        isPlayerTurn,
        finishStatus,
        isGuest,
        isDemo,
        step1,
        step2,
        step3,
        step4
    };
};

export const getCardsData = ({
    initialState,
    gameDataState,
    demoGameState,
    settingsState,
    dealingStepsState,
    boardConditionState
}) => {
    const { showPlaceholder } = boardConditionState;
    const isDemo = initialState.gameInitialState?.gameConfiguration?.isDemo;
    const { isPlayerTurn, cardsToBoard, cardsToLastBeat } = gameDataState;
    const { isAnimationEnabled } = settingsState;
    const { step1, step3 } = dealingStepsState?.steps;
    const { isGuest } = initialState.gameInitialState;
    const isSwitched = demoGameState.isSwitched || false;

    return {
        step1,
        step3,
        isDemo,
        isGuest,
        isSwitched,
        isPlayerTurn,
        cardsToBoard,
        cardsToLastBeat,
        showPlaceholder,
        isAnimationEnabled
    };
};

export const getSettingsContentData = ({ gameDataState, settingsState }) => {
    const { cardsToBoard, cardsToLastBeat, showRoundResult } = gameDataState;
    const changeLoading = settingsState?.changeLoading;

    return {
        changeLoading,
        showRoundResult,
        doLastBeat: !!cardsToLastBeat.length,
        doCardToBoard: !!cardsToBoard.length
    };
};

export const getUserSideData = ({ gameDataState }) => {
    const { showRoundResult, isPlayerWin, finishStatus, opponent: opponentData } = gameDataState;

    return {
        finishStatus,
        showRoundResult,
        isUserWin: isPlayerWin,
        opponentCards: opponentData?.cards
    };
};

export const getPopupWrapperData = ({ popupTypesState, gameDataState }) => {
    const { popupType, popupLoading } = popupTypesState;
    const { isCanRedouble } = gameDataState?.popups?.doubleStake || {};
    const currentStake = gameDataState?.doubleStakeData?.currentStake || 1;

    return {
        isCanRedouble,
        currentStake,
        popupLoading,
        popupType
    };
};

export const getScoreBarWrapperData = ({ initialState, gameDataState }) => {
    const { opponentGameScore, playerGameScore } = gameDataState.gameScore || {};
    const isGuest = initialState.gameInitialState?.isGuest;

    return {
        opponentGameScore,
        playerGameScore,
        isGuest
    };
};

export const getGameIdTimeWrapperData = ({ initialState, historyState }) => {
    const isDemo = initialState.gameInitialState?.gameConfiguration?.isDemo;
    const gameId = initialState.gameInitialState?.gameInfo?.gameId;
    const createTime = historyState.history?.gameInfo?.createTime;

    return {
        createTime,
        isDemo,
        gameId
    };
};

export const getScoreBarContentData = ({ initialState, historyState, gameDataState }) => {
    const { isGuest, playerInitialState, opponentInitialState, gameConfiguration } = initialState.gameInitialState;
    const roundsScores = gameDataState?.gameScore?.roundsScores;
    const { autoPlayEnabled, roundIndex } = historyState || {};
    const incognito = gameConfiguration?.incognito;

    return {
        opponentInitialState,
        playerInitialState,
        autoPlayEnabled,
        roundsScores,
        roundIndex,
        incognito,
        isGuest
    };
};

export const isDealingAnimationSelector = createSelector(
    [
        ({ dealingAnimationCountState }) => dealingAnimationCountState.dealingAnimationCount,
        ({ gameData }) => gameData?.isGameStarted
    ],
    (dealingAnimationCount, isGameStarted) => dealingAnimationCount !== 0 && !isGameStarted
);

export const getCardWrapperData = ({
    dealingStepsState,
    gameDataState,
    settingsState,
    initialState,
    demoGameState
}) => {
    const { cardsToLastBeat, cardsToBoard, isPlayerTurn, lastBeat } = gameDataState;
    const isDemo = initialState.gameInitialState?.gameConfiguration?.isDemo;
    const { isAnimationEnabled, cardViewType, itemUrl } = settingsState;
    const isSwitched = demoGameState.isSwitched || false;
    const { isGuest } = initialState.gameInitialState;
    const { step1 } = dealingStepsState?.steps;

    return {
        isPlayerBeat: !!lastBeat?.isPlayerBeat,
        cardBackImage: itemUrl,
        isAnimationEnabled,
        cardsToLastBeat,
        cardsToBoard,
        cardViewType,
        isPlayerTurn,
        isSwitched,
        isGuest,
        isDemo,
        step1
    };
};

export const getLastBeatData = ({ gameDataState, settingsState, initialState }) => {
    const { cards, isPlayerBeat } = gameDataState.lastBeat || {};
    const { cardViewType } = settingsState;
    const {
        board: { cards: boardCards }
    } = gameDataState;
    const isGuest = initialState.gameInitialState?.isGuest;

    return {
        cards,
        isGuest,
        boardCards,
        cardViewType,
        isPlayerBeat
    };
};

export const getTimerWrapperData = ({ gameDataState, usersTimesState, initialState }) => {
    const { gameTime, stepTime } = initialState.gameInitialState?.gameConfiguration || {};
    const { finishStatus, isPlayerTurn, isPlayerPlayed } = gameDataState;

    return {
        usersTimes: usersTimesState,
        isGameEnded: !!finishStatus,
        isPlayerPlayed,
        isPlayerTurn,
        gameTime,
        stepTime
    };
};

export const getGameCardsWrapperData = ({
    boardConditionState,
    gameDataState,
    demoGameState,
    settingsState,
    initialState
}) => {
    const { board, isPlayerTurn, player, cardsToBoard } = gameDataState;
    const isDemo = initialState.gameInitialState?.gameConfiguration?.isDemo;
    const { showPlaceholder, selectedCard } = boardConditionState;
    const { isAnimationEnabled } = settingsState;
    const { isSwitched } = demoGameState;

    return {
        isDemo,
        isSwitched,
        isPlayerTurn,
        cardsToBoard,
        selectedCard,
        showPlaceholder,
        isAnimationEnabled,
        cards: board?.cards,
        playerCards: player.cards
    };
};
export const getCancelGameWaitingData = ({ gameDataState, initialState, dataReceivedState }) => {
    const { finishStatus, isGameStarted } = gameDataState || {};
    const { dataReceived } = dataReceivedState;
    const { isGuest, tournamentInfo } = initialState.gameInitialState || {};

    return {
        isGuest,
        dataReceived,
        isGameStarted,
        isGameEnded: finishStatus,
        tournamentId: tournamentInfo?.tournamentId
    };
};

export const getHistorySlideData = ({ historyState }) => {
    const { stepIndex, roundIndex, history, autoPlayEnabled } = historyState;

    return { stepIndex, roundIndex, rounds: history?.rounds, autoPlayEnabled };
};

export const getOnBoardCardsData = ({
    boardConditionState,
    dealingStepsState,
    gameDataState,
    settingsState,
    demoGameState,
    initialState
}) => {
    const {
        finalData,
        isPlayerTurn,
        cardsToBoard,
        cardsToLastBeat,
        isBeforeDealing,
        board: { cards, cardsAfterStep }
    } = gameDataState;

    const isDemo = initialState.gameInitialState?.gameConfiguration?.isDemo;
    const isGuest = initialState.gameInitialState?.isGuest;
    const { isActivePlaceholder } = boardConditionState;
    const { step4, step5 } = dealingStepsState?.steps;
    const { isAnimationEnabled } = settingsState;
    const isSwitched = demoGameState.isSwitched;

    return {
        cards,
        step4,
        step5,
        isDemo,
        isGuest,
        finalData,
        isSwitched,
        cardsToBoard,
        isPlayerTurn,
        cardsAfterStep,
        isBeforeDealing,
        cardsToLastBeat,
        isAnimationEnabled,
        isActivePlaceholder
    };
};

export const getStepData = ({ dealingStepsState }) => {
    const { step1, step2, step3, step4 } = dealingStepsState?.steps;

    return {
        isDealing: step1 || step2 || step3 || step4
    };
};

export const getDealerDeckData = ({ gameDataState, settingsState, boardConditionState, dealingStepsState }) => {
    const { isDeckDisappearing, isDeckHidden } = boardConditionState;
    const { isAnimationEnabled, cardViewType } = settingsState;
    const { showRoundResult, isDeckEmpty } = gameDataState;
    const { step1 } = dealingStepsState?.steps;

    return {
        isDeckDisappearing,
        isAnimationEnabled,
        showRoundResult,
        isDeckHidden,
        cardViewType,
        isDeckEmpty,
        step1
    };
};

export const getSettingsWrapperData = ({ gameDataState, settingsState }) => {
    const { cardsToBoard, cardsToLastBeat } = gameDataState;
    const { settingsRejected, isAnimationEnabled } = settingsState;

    return {
        settings: settingsState,
        isAnimationEnabled,
        settingsRejected,
        cardsToLastBeat,
        cardsToBoard
    };
};

export const getAppIndexData = ({ initialState, gameDataState }) => {
    const isDemo = initialState?.gameInitialState?.gameConfiguration?.isDemo;
    const finishStatus = gameDataState.finishStatus;

    return {
        finishStatus,
        isDemoGame: isDemo
    };
};

export const getToggleSwitchDemoData = ({ demoGameState }) => {
    const { isSwitched, loading } = demoGameState;

    return {
        isSwitched: isSwitched || false,
        loading
    };
};

export const getGameInfoWrapperData = ({ gameDataState, initialState }) => {
    const gameConfiguration = initialState?.gameInitialState?.gameConfiguration;
    const currentStake = gameDataState?.doubleStakeData?.currentStake;

    return {
        gameConfiguration,
        currentStake
    };
};

export const getPlayerInfoData = ({ gameDataState, initialState, connectionState }) => {
    const {
        doubleStakeData,
        player: { score, clubs, soor, notification }
    } = gameDataState;
    const isGuest = initialState.gameInitialState?.isGuest;
    const { opponent, player } = connectionState;

    return {
        isOpponentConnected: opponent,
        isPlayerConnected: player,
        doubleStakeData,
        notification,
        isGuest,
        score,
        clubs,
        soor
    };
};

export const getOpponentInfoData = ({ gameDataState, initialState, connectionState }) => {
    const {
        doubleStakeData,
        opponent: { score, clubs, soor, notification }
    } = gameDataState;
    const isGuest = initialState.gameInitialState?.isGuest;
    const { opponent, player } = connectionState;

    return {
        isOpponentConnected: opponent,
        isPlayerConnected: player,
        doubleStakeData,
        notification,
        isGuest,
        score,
        clubs,
        soor
    };
};
