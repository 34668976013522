import AppConstants, { GAME_DATA_KEYS } from "../constants";
import { shallowEqual, useSelector } from "react-redux";
import values from "../constants/cardValues";
import { store } from "../store/store";
const { JACK_VALUE } = AppConstants.cardValues;

const {
    dimensions: {
        DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE,
        DEFAULT_ASPECT_RATIO_WIDTH_MOBILE,
        DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP,
        DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP,
        FONT_SIZE_SCALE_INDEX_DESKTOP,
        FONT_SIZE_SCALE_INDEX_LANDSCAPE,
        FONT_SIZE_SCALE_INDEX_PORTRAIT
    }
} = AppConstants;

const gameResolutionMobile = DEFAULT_ASPECT_RATIO_WIDTH_MOBILE / DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE;
const gameResolutionDesktop = DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP / DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP;

export const getUrlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { isMobile } = params;
    params.lang = params.lang || "en";
    params.isDemo = params.isDemo?.toLowerCase() === "true" || false;
    params.isMobile = (isMobile && isMobile.toLowerCase()) === "true" || false;
    params.isHistory = window.location?.pathname?.includes("/game/history");
    return params;
};

export const setRootFontSize = (gameWidth, isMobile, isPortrait) => {
    const rootElement = document.querySelector("html");
    const sizeIndex = isMobile
        ? isPortrait
            ? FONT_SIZE_SCALE_INDEX_PORTRAIT
            : FONT_SIZE_SCALE_INDEX_LANDSCAPE
        : FONT_SIZE_SCALE_INDEX_DESKTOP;

    rootElement.style.fontSize = `${gameWidth / sizeIndex}px`;
};

export const calculateGameDimension = (clientWidth, clientHeight) => {
    const { isMobile } = getUrlParams();

    const isPortrait = isMobile && clientWidth < clientHeight;
    const isFullWidth = clientWidth / clientHeight < gameResolutionDesktop;
    const dimensionsConfig = {
        gameWidth: isFullWidth ? clientWidth : clientHeight * gameResolutionDesktop,
        gameHeight: !isFullWidth ? clientHeight : clientWidth / gameResolutionDesktop
    };

    dimensionsConfig.fontSize = dimensionsConfig.gameWidth / FONT_SIZE_SCALE_INDEX_LANDSCAPE;

    if (isMobile) {
        if (isPortrait) {
            const isFullHeight = clientHeight / clientWidth < gameResolutionMobile;

            dimensionsConfig.gameWidth = !isFullHeight ? clientWidth : clientHeight / gameResolutionMobile;
            dimensionsConfig.gameHeight = isFullHeight ? clientHeight : clientWidth * gameResolutionMobile;
        } else {
            const isWithFullWidth = clientWidth / clientHeight < gameResolutionMobile;

            dimensionsConfig.gameWidth = isWithFullWidth ? clientWidth : clientHeight * gameResolutionMobile;
            dimensionsConfig.gameHeight = isWithFullWidth ? clientHeight : clientWidth / gameResolutionMobile;
        }
    }

    setRootFontSize(dimensionsConfig.gameWidth, isMobile, isPortrait);
    return { ...dimensionsConfig, clientWidth, clientHeight };
};

export const getCardPositionCalcStyle = ({ index, disableRotation, cards, isPlayer }) => {
    const { isMobile } = getUrlParams();
    const { clientWidth, clientHeight } = document.body;
    const isPortrait = isMobile && clientWidth < clientHeight;
    let [cardsLength, lastCardCalc, degPoint, xPosPoint] = [cards?.length, 1, 2, isPortrait ? 35 : 40];
    const [calcIndexPoint, cardsIsOdd] = [index - cardsLength / 2, cardsLength % 2 === 1];
    const yPosIndex = Math.floor(Math.abs(calcIndexPoint)) - +(calcIndexPoint < 0);
    const cardsYPositionsCalc = [...cards]
        .splice(0, cardsLength / 2)
        ?.map((elem, i) => (lastCardCalc = i + lastCardCalc));

    const [calcIndexInOdd, yPos] = [
        cardsIsOdd ? Math.ceil(calcIndexPoint) : calcIndexPoint,
        cardsYPositionsCalc[yPosIndex] || 0
    ];

    let [translateX, rotate] = [xPosPoint * calcIndexInOdd, degPoint * calcIndexInOdd];

    if (!cardsIsOdd) {
        translateX += xPosPoint / 2;
        rotate += degPoint / 2;
    }

    const rotationPositionCalc = isPlayer ? rotate : -rotate;
    const rotationPoint = disableRotation ? 0 : rotationPositionCalc;
    return {
        transform: `translate(${translateX + 50}%, ${isPlayer ? Math.abs(yPos) : -yPos}%) rotate(${rotationPoint}deg)`,
        zIndex: index
    };
};

const separateHost = () => {
    let host = window.location.host.split(".");
    if (host.length > 2) {
        host.shift();
    }
    host = host.join(".");
    return host;
};

export const handleIsDynamicUrl = urlData => {
    const key = Object.keys(urlData)[0];
    let url = urlData[key];
    if (process.env[`${key}_IS_DYNAMIC`] === "true") {
        const host = separateHost();
        url = `${url}${host}`;
    }
    return url;
};

export const useShallowSelector = selector => {
    return useSelector(selector, shallowEqual);
};

export const sum = arr => arr.reduce((a, b) => a + values[b], 0);

export const translations = (() => {
    let translationState;

    return key => {
        if (!translationState) {
            translationState = store.getState().translationState;
        }

        return translationState[key] || key;
    };
})();

export const callWithTimeoutRejection = (apiCall, timeout = 3000) => {
    const timeoutPromise = new Promise((resolve, reject) =>
        setTimeout(() => reject(new Error("Timeout to receive response has expired")), timeout)
    );
    const resultPromise = apiCall();

    // for testing purposes
    // const resultPromise = new Promise(resolve => setTimeout(() => resolve(apiCall()), 5000));

    return Promise.race([timeoutPromise, resultPromise]);
};

export const buildCombinations = (arr, selectedVal, boardSelects) => {
    const len = arr.length,
        possibleCards = [],
        subArrays = Array(Math.pow(2, len)).fill(0);

    subArrays.forEach((_, subArrInd) => {
        let curInd = -1,
            currentArrInd = subArrInd,
            res = [];
        while (++curInd < len) {
            currentArrInd & 1 && !boardSelects.includes(arr[curInd]) && res.push(arr[curInd]);
            currentArrInd = currentArrInd >> 1;
        }

        if (sum(res) + sum(boardSelects) + selectedVal === JACK_VALUE && !boardSelects.includes(subArrInd)) {
            possibleCards.push(res);
        }
    });

    return possibleCards;
};

export const isFitForEleven = (cards, selectedVal, boardSelects) => {
    const validCards = cards?.filter(i => values[i] <= JACK_VALUE - selectedVal);
    const elevenCombinations = buildCombinations(validCards || [], selectedVal, boardSelects);

    return elevenCombinations.flat();
};

export const generateCardStepStyles = (current, placeholderId) => {
    const placeholderRect = document.getElementById(placeholderId)?.getBoundingClientRect();
    const placeholderHeight = placeholderRect?.height;
    const placeholderWidth = placeholderRect?.width;

    const currentRect = current?.getBoundingClientRect();

    const currentHeight = currentRect?.height;
    const currentWidth = currentRect?.width;

    return { width: placeholderWidth / currentWidth, height: placeholderHeight / currentHeight };
};

/**
 * getBrowserSpecificProperties
 * @returns {object}
 */
function getBrowserSpecificProperties() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    return { HIDDEN: hidden, VISIBILITY_CHANGE: visibilityChange };
}

export const { HIDDEN, VISIBILITY_CHANGE } = getBrowserSpecificProperties();

export const dataHandler = data => {
    for (const key in data) {
        if (Object.hasOwn(data, key)) {
            if (data[key] && typeof data[key] === "object" && Object.keys(data[key]).length) {
                dataHandler(data[key]);
            }

            if (GAME_DATA_KEYS[key]) {
                data[GAME_DATA_KEYS[key]] = data[key];
                delete data[key];
            }
        }
    }
    return data;
};
