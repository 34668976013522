import { Howl } from "howler";

const config = {
    src: ["/Sounds.mp3"],
    sprite: {
        turnCard: [1000, 800],
        playerDealing: [2000, 950],
        otherDealing: [3000, 900],
        throwCard: [4000, 1000]
    },
    preload: true,
    html5: true,
    pool: 0
};

export const [enableSounds, disableSounds, soundPlayByName] = (() => {
    let sounds = null;

    const disable = () => {
        sounds = null;
    };

    const setup = () => {
        if (!sounds) {
            sounds = new Howl(config);
        }
    };

    const play = name => {
        sounds?.play(name);
    };
    return [setup, disable, play];
})();
