import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
    pendingDealingEnd: false,
    steps: {
        step1: false
    }
};

export const dealingStepsSlice = createSlice({
    name: "dealingStepsState",
    initialState: { ...initialState },
    reducers: {
        saveDealingSteps: (state, action) => {
            state.steps = { ...state.steps, ...action.payload };
        },
        resetDealingStepsState: () => ({
            ...initialState
        })
    }
});

const selectSelf = state => state.dealingStepsState;

export const selectActiveDealingStep = createSelector(selectSelf, state =>
    Object.keys(state.steps)?.find(stepKey => state.steps[stepKey])
);

export const { saveDealingSteps, resetDealingStepsState } = dealingStepsSlice.actions;
export default dealingStepsSlice.reducer;
