import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isConnectionLow: false
};

export const lowConnectionSlice = createSlice({
    name: "lowConnectionState",
    initialState,
    reducers: {
        saveIsConnectionLow: (state, action) => {
            state.isConnectionLow = action.payload;
        }
    }
});

export const { saveIsConnectionLow } = lowConnectionSlice.actions;
export default lowConnectionSlice.reducer;
