import { batch } from "react-redux";
import newHandController from "./newHandController";
import { soundPlayByName } from "../services/sounds";
import newRoundController from "./newRoundController";
import { saveUsersTimes } from "../store/reducers/usersTimesSlice";
import { saveBoardCondition } from "../store/reducers/boardConditionSlice";
import { saveStepData, setFinalData } from "../store/reducers/gameDataSlice";

const stepDataController = (res, isFirstTime) => (dispatch, getState) => {
    if (isFirstTime) {
        dispatch(setFinalData(res));
    }

    const {
        dealingStepsState: { step1 },
        settingsState: { isAnimationEnabled },
        gameDataState: {
            cardsToBoard,
            cardsToLastBeat,
            player: { ...playerData },
            opponent: { ...opponentData },
            board: { cards: stateBoardCards }
        },
        dealingAnimationCountState: { dealingAnimationCount },
        boardConditionState: { showPlaceholder, isActivePlaceholder, selectedCard }
    } = getState();

    const {
        newHand,
        newRound,
        move: resMove,
        player: resPlayer,
        opponent: resOpponent,
        lastBeat: resLastBeat,
        boardCards: resBoardCards,
        isPlayerPlayed: resIsPlayerPlayed,
        opponent: { cards: resOpponentCards }
    } = res;

    if (selectedCard && resMove.cardId !== selectedCard && resIsPlayerPlayed) {
        dispatch(saveBoardCondition({ selectedCard: null }));
    }

    if (newHand) {
        return dispatch(newHandController(res));
    } else if (newRound) {
        return dispatch(newRoundController(res));
    }

    let stepResultData;
    const stepResultMainData = {
        ...res,
        isGameStarted: true
    };
    const isLastBeat = resLastBeat?.cards?.includes(resMove.cardId);

    const updateTimers = () => {
        dispatch(
            saveUsersTimes({
                player: { stepTime: resPlayer.stepTime, gameTime: resPlayer.gameTime },
                opponent: { stepTime: resOpponent.stepTime, gameTime: resOpponent.gameTime }
            })
        );
    };

    if (isAnimationEnabled) {
        stepResultData = {
            ...stepResultMainData,
            player: {
                ...playerData,
                ...resPlayer,
                cards: resIsPlayerPlayed ? playerData.cards : resPlayer.cards,
                cardsAfterStep: resPlayer.cards
            },
            opponent: {
                ...opponentData,
                ...resOpponent,
                cards: !resIsPlayerPlayed ? [...resOpponentCards, resMove.cardId] : resOpponentCards,
                cardsAfterStep: resOpponentCards
            },
            lastBeat: isLastBeat
                ? {
                      ...resLastBeat,
                      cardsAfterStep: [...resLastBeat.cards],
                      cards: []
                  }
                : resLastBeat,
            board: {
                ...res.board,
                cards: isLastBeat ? stateBoardCards : resBoardCards.filter(i => i !== resMove.cardId),
                cardsAfterStep: resBoardCards
            },
            cardsToLastBeat: isLastBeat ? resLastBeat.cards : [],
            cardsToBoard: !isLastBeat ? [resMove.cardId] : [],
            newData: res
        };
    } else {
        stepResultData = {
            ...stepResultMainData,
            player: {
                ...playerData,
                ...resPlayer
            },
            opponent: {
                ...opponentData,
                ...resOpponent
            },
            board: {
                cards: resBoardCards
            },
            newData: res
        };
    }
    soundPlayByName("throwCard");

    if (
        isAnimationEnabled &&
        !cardsToBoard.length &&
        !dealingAnimationCount &&
        !cardsToLastBeat.length &&
        !isLastBeat &&
        !step1
    ) {
        batch(() => {
            if (!showPlaceholder) {
                dispatch(saveBoardCondition({ showPlaceholder: true }));
            }
            dispatch(saveStepData(stepResultData));
            updateTimers();
        });
    } else {
        batch(() => {
            if ((showPlaceholder || isActivePlaceholder) && !cardsToBoard.length) {
                dispatch(saveBoardCondition({ showPlaceholder: false, isActivePlaceholder: false }));
            }
            dispatch(saveStepData(stepResultData));
            updateTimers();
        });
    }
};

export default stepDataController;
