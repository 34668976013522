import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    roundIndex: 0,
    stepIndex: 0,
    history: null,
    autoPlayEnabled: false
};

export const historySlice = createSlice({
    name: "historyState",
    initialState,
    reducers: {
        saveHistory: (state, action) => {
            state.history = action.payload;
        },
        setHistoryStep: (state, action) => ({
            ...state,
            ...action.payload
        }),
        toggleHistoryAutoPlay: state => {
            state.autoPlayEnabled = !state.autoPlayEnabled;
        }
    }
});

export const { setHistoryStep, saveHistory, toggleHistoryAutoPlay } = historySlice.actions;
export default historySlice.reducer;
