import { createSlice } from "@reduxjs/toolkit";
import BoardImage from "../../../assets/images/bg-image.png";
import CardBackImg from "../../../assets/images/cardBackTheme1.png";
import { IS_IOS_CHROME } from "../../constants";

const initialState = {
    currentTheme: 1,
    itemUrl: CardBackImg,
    backgroundUrl: BoardImage,
    themes: [
        {
            id: 1,
            name: "Classic",
            url: BoardImage
        }
    ],
    themeNumber: 1,
    isSoundOn: false,
    cardViewType: "c",
    isAnimationEnabled: !IS_IOS_CHROME,
    loading: false,
    changeLoading: false,
    resRejected: null,
    resFulfilled: null
};

export const settingsSlice = createSlice({
    name: "settingsState",
    initialState,
    reducers: {
        saveSettings: (state, action) => ({
            ...state,
            ...action.payload
        })
    }
});

export const { saveSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
