import { createSlice } from "@reduxjs/toolkit";

const initialState = { dataReceived: false };

export const dataReceivedSlice = createSlice({
    name: "dataReceivedState",
    initialState: { ...initialState },
    reducers: {
        saveGameDataReceivedSuccess: state => {
            state.dataReceived = true;
        },
        resetGameDataReceived: () => ({
            ...initialState
        })
    }
});

export const { saveGameDataReceivedSuccess, resetGameDataReceived } = dataReceivedSlice.actions;
export default dataReceivedSlice.reducer;
