import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./core/store/store";
import AppIndex from "./AppIndex";
import "./assets/styles/index.scss";

ReactDOM.render(
    <Provider store={store}>
        <AppIndex />
    </Provider>,
    document.getElementById("root")
);
