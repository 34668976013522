import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dealingAnimationCount: 0
};

export const dealingAnimationCountSlice = createSlice({
    name: "dealingAnimationCount",
    initialState,
    reducers: {
        decreaseDealingAnimationsCount: state => {
            state.dealingAnimationCount && state.dealingAnimationCount--;
        },
        increaseDealingAnimationsCount: state => {
            state.dealingAnimationCount++;
        },
        setDealingAnimationsCountByAmount: (state, action) => {
            state.dealingAnimationCount = action.payload;
        },
        resetDealingAnimationCountState: () => ({ ...initialState })
    }
});

export const {
    decreaseDealingAnimationsCount,
    increaseDealingAnimationsCount,
    resetDealingAnimationCountState,
    setDealingAnimationsCountByAmount
} = dealingAnimationCountSlice.actions;
export default dealingAnimationCountSlice.reducer;
