import React, { useState, Suspense, lazy } from "react";
import { isDemo, lang } from "./core/constants/urlParams";
import { useShallowSelector } from "./core/helpers";
import { getAppIndexData } from "./core/store/selectors";

const App = lazy(() => import("./App"));
const DemoStartButton = lazy(() => import("./components/demoStartButton/DemoStartButton"));

document.documentElement.lang = lang || "en";

const AppIndex = () => {
    const [isStartDemo] = useState(isDemo);
    const { isDemoGame, finishStatus } = useShallowSelector(getAppIndexData);

    return (
        <Suspense fallback={null}>
            {isStartDemo || (isDemoGame && finishStatus) ? <DemoStartButton /> : <App />}
        </Suspense>
    );
};

export default AppIndex;
