import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updater: false,
    player: null,
    opponent: null
};

export const usersTimesSlice = createSlice({
    name: "usersTimesState",
    initialState,
    reducers: {
        saveUsersTimes: (state, action) => ({
            ...state,
            ...action.payload,
            updater: !state.updater
        })
    }
});

export const { saveUsersTimes } = usersTimesSlice.actions;
export default usersTimesSlice.reducer;
