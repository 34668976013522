import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    player: true,
    opponent: true
};

export const connectionSlice = createSlice({
    name: "connectionState",
    initialState,
    reducers: {
        saveConnection: (state, action) => ({
            ...state,
            ...action.payload
        })
    }
});

export const { saveConnection } = connectionSlice.actions;
export default connectionSlice.reducer;
