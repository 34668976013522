import { batch } from "react-redux";
import { saveDealingSteps } from "../store/reducers/dealingStepsSlice";
import { saveBoardCondition } from "../store/reducers/boardConditionSlice";
import { setDealingAnimationsCountByAmount } from "../store/reducers/dealingAnimationCountSlice";
import { saveAfterStepData, saveNewRoundOrHandData, setGameDataProperty } from "../store/reducers/gameDataSlice";

const afterStepDataController = () => (dispatch, getState) => {
    const {
        gameDataState: {
            move,
            lastBeat,
            newHandAfterStep,
            newRoundAfterStep,
            board: { ...boardData },
            player: { ...playerData },
            opponent: { ...opponentData }
        }
    } = getState();

    const isLastBeat = lastBeat?.cardsAfterStep?.includes(move.cardId);

    const stepResultData = {
        showRoundResult: !!newRoundAfterStep,
        board: {
            ...boardData,
            cards: newRoundAfterStep
                ? !isLastBeat
                    ? [...(boardData.cards || []), ...(boardData.cards?.includes(move.cardId) ? [] : [move.cardId])]
                    : boardData.cards.filter(i => !lastBeat.cardsAfterStep.includes(i))
                : boardData.cardsAfterStep || [],
            cardsAfterStep: null
        },
        player: { ...playerData, cards: playerData.cardsAfterStep || [], cardsAfterStep: null },
        opponent: { ...opponentData, cards: opponentData.cardsAfterStep || [], cardsAfterStep: null },
        cardsToBoard: [],
        cardsToLastBeat: []
    };

    if (newRoundAfterStep) {
        stepResultData.isDeckEmpty = true;
    }

    if (isLastBeat) {
        stepResultData.lastBeat = {
            ...lastBeat,
            cards: lastBeat.cardsAfterStep,
            cardsAfterStep: null
        };
    }

    batch(() => {
        const boardCondition = { showPlaceholder: false, isActivePlaceholder: false };
        if (newRoundAfterStep) {
            boardCondition.isDeckHidden = false;
        }

        dispatch(saveBoardCondition(boardCondition));
        dispatch(saveAfterStepData(stepResultData));
    });

    if (newHandAfterStep) {
        batch(() => {
            dispatch(saveNewRoundOrHandData());
            dispatch(saveDealingSteps({ step3: true }));
            dispatch(setDealingAnimationsCountByAmount(12));
        });
    } else if (newRoundAfterStep) {
        setTimeout(() => {
            batch(() => {
                dispatch(setGameDataProperty({ showRoundResult: false, isBeforeDealing: false }));
                dispatch(setDealingAnimationsCountByAmount(16));
                dispatch(saveDealingSteps({ step4: true }));
                dispatch(saveNewRoundOrHandData());
            });
        }, 1300);
    }
};

export default afterStepDataController;
