import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSwitched: false,
    loading: false
};

export const demoGameSlice = createSlice({
    name: "demoGameState",
    initialState,
    reducers: {
        saveSwitchMode: (state, action) => {
            state.isSwitched = action.payload;
        },
        saveLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const { saveSwitchMode, saveLoading } = demoGameSlice.actions;
export default demoGameSlice.reducer;
