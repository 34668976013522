import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Me: "Me",
    You: "You",
    Info: "Info",
    Stay: "STAY",
    Leave: "LEAVE",
    Prize: "Prize",
    Pasoor: "Pasoor",
    Accept: "ACCEPT",
    Double: "DOUBLE",
    Themes: "Themes",
    Winner: "Winner",
    TotalSoor: "Soor",
    GameID: "Game ID",
    Confirm: "CONFIRM",
    Refresh: "REFRESH",
    Player1: "Player1",
    Player2: "Player2",
    Private: "Private",
    TimeOut: "Time Out",
    TotalScore: "Score",
    Redouble: "Redouble",
    Incognito: "Incognito",
    Surrender: "SURRENDER",
    Animation: "Animation",
    GuestMode: "Guest Mode",
    RedoubleBtn: "REDOUBLE",
    StayTuned: "Stay Tuned!",
    SurrenderLowCase: "Surrender",
    LeaveTheGame: "LEAVE THE GAME",
    YourOpponents: "Your Opponent’s",
    DoubleStake: "DOUBLE YOUR STAKE",
    WeakConnection: "YOUR CONNECTION IS TOO WEAK",
    StakeDoubling: "Would You Like To Double The Stake?",
    FinalStakeDoubling: "Your final stake after doubling:",
    PressRefresh: "PRESS THE REFRESH BUTTON TO CONTINUE THE GAME",
    PleaseWaitUntilPlayersJoin: "Please wait until the players will join the game.",
    StakeDoublingOffer: "Your opponent offers to double the stake.Otherwise you will lose",
    PleaseWaitUntilOpponentJoin: "Please wait until the opponent will join the game, otherwise you will lose.",
    Close: "CLOSE",
    SoundEffects: "Sound Effects",
    Cancel: "CANCEL",
    Settings: "Settings",
    MaxStake: "Max Stake",
    BaseStake: "Base/Current Stake",
    ConnectionLost: "Connection lost",
    GameAutomaticallyResumeConnectionRestored: "The Game Will Automatically Resume When The Connection Is Restored",
    // Additional
    Draw: "Draw",
    Win: "You Win!",
    GameScore: "SCORE",
    Revanche: "REVANCHE",
    PlayerID: "Player ID",
    Attention: "Attention!",
    CardsHand: "Cards Hand",
    TimeIsUp: "Your Time Is Up!",
    RevancheOffer: "REVANCHE OFFER",
    ConnectionLostBtn: "CONNECTION LOST",
    OpponentPlayAgain: "Your Opponent Wants To Play Again!",
    LeaveTheGameConfirmationText: "Do You Want To Surrender And Leave The Game?",
    AcceptOffer: "Do You Want To Accept The Offer And Play With The Same Conditions?"
};

export const translationSlice = createSlice({
    name: "translationState",
    initialState,
    reducers: {
        saveTranslation: (state, action) => ({
            ...state,
            ...action.payload
        })
    }
});

export const { saveTranslation } = translationSlice.actions;
export default translationSlice.reducer;
