const values = {
    "0": null,

    "1": 1,
    "2": 13,
    "3": 12,
    "4": 11,
    "5": 10,
    "6": 9,
    "7": 8,
    "8": 7,
    "9": 6,
    "10": 5,
    "11": 4,
    "12": 3,
    "13": 2,

    "14": 1,
    "15": 13,
    "16": 12,
    "17": 11,
    "18": 10,
    "19": 9,
    "20": 8,
    "21": 7,
    "22": 6,
    "23": 5,
    "24": 4,
    "25": 3,
    "26": 2,

    "27": 1,
    "28": 13,
    "29": 12,
    "30": 11,
    "31": 10,
    "32": 9,
    "33": 8,
    "34": 7,
    "35": 6,
    "36": 5,
    "37": 4,
    "38": 3,
    "39": 2,

    "40": 1,
    "41": 13,
    "42": 12,
    "43": 11,
    "44": 10,
    "45": 9,
    "46": 8,
    "47": 7,
    "48": 6,
    "49": 5,
    "50": 4,
    "51": 3,
    "52": 2
};

export default values;
