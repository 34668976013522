import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    player: {
        cards: []
    },
    opponent: {
        cards: []
    },
    board: {
        cards: []
    },
    cardsToLastBeat: [],
    cardsToBoard: []
};

export const gameDataSlice = createSlice({
    name: "gameData",
    initialState: { ...initialState },
    reducers: {
        saveGameData: (state, action) => action.payload,
        excludeCardFromAnimations: (state, { payload }) => {
            const { dataKey, cardId } = payload;

            if (dataKey === "cardsToLastBeat") {
                state.player.cards = state.player?.cards.filter(i => i !== cardId);
                state.opponent.cards = [...(state.opponent?.cards || [])].filter(i => i !== cardId);
                state.board.cards = [...(state.board?.cards || [])].filter(i => i !== cardId);
            }

            state[dataKey] = [...state[dataKey].filter(i => i !== cardId)];
        },
        saveStepData: (state, action) => ({
            ...state,
            ...action.payload
        }),
        saveNewHand: (state, action) => action.payload,
        saveNewRound: (state, action) => action.payload,
        saveAfterStepData: (state, action) => ({
            ...state,
            ...action.payload
        }),
        saveGameResultData: (state, action) => ({
            ...state,
            ...action.payload,
            player: {
                ...state.player,
                ...action.payload.player
            },
            opponent: {
                ...state.opponent,
                ...action.payload.opponent
            },
            board: {
                ...state.board
            }
        }),
        saveNewRoundOrHandData: state => {
            const { player, opponent } = state.newRoundAfterStep || {};

            state.player = {
                ...state.player,
                ...(player || {}),
                cards: state.player.cardsAfterNewHand,
                cardsAfterNewHand: null,
                cardsAfterStep: null
            };
            state.opponent = {
                ...state.opponent,
                ...(opponent || {}),
                cards: state.opponent.cardsAfterNewHand,
                cardsAfterNewHand: null,
                cardsAfterStep: null
            };
            if (state.newRoundAfterStep) {
                state.lastBeat = null;
                state.isDeckEmpty = false;
                state.board.cards = state.board.cardsAfterNewHand;
            }
            state.newHand = state.newHandAfterStep;
            state.newRound = state.newRoundAfterStep;
            state.newRoundAfterStep = null;
            state.newHandAfterStep = null;
        },
        setGameDataProperty: (state, action) => ({
            ...state,
            ...action.payload
        }),
        resetGameDateState: state => ({
            isGameStarted: state.isGameStarted,
            ...initialState
        }),
        setFinalData: (state, action) => {
            state.finalData = action.payload;
        },
        saveRotateData: (state, action) => ({
            ...state,
            ...action.payload
        })
    }
});

export const gameDataOptimized = state => {
    const { popups, isPlayerTurn, finishStatus } = state.gameDataState;

    return {
        popups,
        isPlayerTurn,
        finishStatus
    };
};

export const {
    setFinalData,
    saveNewHand,
    saveNewRound,
    saveGameData,
    saveStepData,
    saveAfterStepData,
    resetGameDateState,
    saveGameResultData,
    setGameDataProperty,
    saveNewRoundOrHandData,
    excludeCardFromAnimations
} = gameDataSlice.actions;
export default gameDataSlice.reducer;
