import { createSlice } from "@reduxjs/toolkit";

export const initialStateSlice = createSlice({
    name: "gameInitialState",
    initialState: {
        gameInitialState: {}
    },
    reducers: {
        saveInitialState: (state, action) => {
            state.gameInitialState = action.payload;
        }
    }
});

export const { saveInitialState } = initialStateSlice.actions;
export default initialStateSlice.reducer;
