import { batch } from "react-redux";
import { soundPlayByName } from "../services/sounds";
import { saveGameData, saveNewRound } from "../store/reducers/gameDataSlice";
import { saveUsersTimes } from "../store/reducers/usersTimesSlice";
import { saveBoardCondition } from "../store/reducers/boardConditionSlice";

const newRoundController = res => (dispatch, getState) => {
    const { newRound, lastBeat, isPlayerPlayed, player: resPlayer, opponent: resOpponent } = res;
    const {
        settingsState: { isAnimationEnabled },
        gameDataState,
        gameDataState: {
            cardsToBoard,
            cardsToLastBeat,
            player: { ...playerData },
            opponent: { ...opponentData },
            player: { cards: playerCards },
            board: { cards: stateBoardCards },
            opponent: { cards: opponentCards }
        },
        boardConditionState: { showPlaceholder, isActivePlaceholder }
    } = getState();

    const updateTimers = () => {
        dispatch(
            saveUsersTimes({
                player: { stepTime: newRound.player.stepTime, gameTime: newRound.player.gameTime },
                opponent: { stepTime: newRound.opponent.stepTime, gameTime: newRound.opponent.gameTime }
            })
        );
    };

    const isLastBeat = lastBeat?.cards.includes(res.move.cardId);
    const stepResultMainData = {
        ...gameDataState,
        ...res
    };

    let stepResultData;

    if (isAnimationEnabled) {
        stepResultData = {
            ...stepResultMainData,
            ...newRound,
            isBeforeDealing: true,
            newRound: null,
            isDeckEmpty: false,
            newRoundAfterStep: newRound,
            player: {
                ...playerData,
                ...resPlayer,
                cards: playerCards,
                cardsAfterStep: resPlayer?.cards,
                cardsAfterNewHand: newRound.player?.cards
            },
            opponent: {
                ...opponentData,
                ...resOpponent,
                cardsAfterStep: null,
                cards: opponentCards,
                cardsAfterNewHand: newRound.opponent?.cards
            },
            board: {
                cardsAfterStep: null,
                cards: stateBoardCards,
                cardsAfterNewHand: newRound.boardCards
            },
            newData: res,
            gameScore: {
                ...newRound.gameScore,
                roundsScores: [
                    ...(gameDataState.gameScore.roundsScores || []),
                    ...(newRound.gameScore.roundsScores || [])
                ]
            }
        };

        stepResultData.cardsToLastBeat = isLastBeat ? lastBeat.cards : [];
        stepResultData.cardsToBoard = !isLastBeat ? [res.move.cardId] : [];
        stepResultData.opponent.cards = !isPlayerPlayed ? [res.move.cardId] : opponentCards;

        if (isLastBeat) {
            stepResultData = {
                ...stepResultData,
                lastBeat: {
                    ...stepResultData.lastBeat,
                    cardsAfterStep: [...(lastBeat.cards || [])],
                    cards: []
                }
            };
        }

        batch(() => {
            if (
                !isLastBeat &&
                !cardsToBoard.length &&
                !cardsToLastBeat.length &&
                (!showPlaceholder || !isActivePlaceholder)
            ) {
                dispatch(saveBoardCondition({ showPlaceholder: true }));
            }
            dispatch(saveNewRound(stepResultData));
            updateTimers();
        });
    } else {
        let newRoundFull = {
            ...newRound,
            gameScore: {
                ...newRound.gameScore,
                roundsScores: [
                    ...(gameDataState.gameScore.roundsScores || []),
                    ...(newRound.gameScore.roundsScores || [])
                ]
            }
        };

        stepResultData = {
            ...stepResultMainData,
            ...newRound,
            player: {
                ...playerData,
                ...resPlayer,
                cards: []
            },
            opponent: {
                ...opponentData,
                ...resOpponent,
                cards: []
            },
            board: {
                cards: isLastBeat
                    ? stateBoardCards.filter(i => !lastBeat.cards.includes(i))
                    : [...stateBoardCards, ...(stateBoardCards?.includes(res.move.cardId) ? [] : [res.move.cardId])]
            },
            newRoundAfterStep: newRoundFull,
            showRoundResult: true,
            newRound: null,
            newData: res,
            gameScore: {
                ...newRound.gameScore,
                roundsScores: [
                    ...(gameDataState.gameScore.roundsScores || []),
                    ...(newRound.gameScore.roundsScores || [])
                ]
            }
        };

        batch(() => {
            if (showPlaceholder || isActivePlaceholder) {
                dispatch(saveBoardCondition({ showPlaceholder: false, isActivePlaceholder: false }));
            }
            dispatch(saveNewRound(stepResultData));
            updateTimers();
        });

        setTimeout(() => {
            const newRoundData = {
                ...stepResultMainData,
                ...newRoundFull,
                opponent: {
                    ...stepResultData.opponent,
                    ...stepResultData.newRoundAfterStep.opponent
                },
                player: {
                    ...stepResultData.player,
                    ...stepResultData.newRoundAfterStep.player
                },
                board: {
                    ...stepResultData.board,
                    cards: stepResultData.newRoundAfterStep.boardCards
                },
                newRoundAfterStep: null,
                showRoundResult: false,
                lastBeat: null
            };
            dispatch(saveGameData(newRoundData));
            soundPlayByName("playerDealing");
        }, 1300);
    }
};

export default newRoundController;
