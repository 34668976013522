import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCard: null,
    isDeckHidden: false,
    showPlaceholder: false,
    isDeckDisappearing: false,
    isActivePlaceholder: false
};

export const boardConditionSlice = createSlice({
    name: "boardConditionState",
    initialState,
    reducers: {
        saveBoardCondition: (state, action) => ({
            ...state,
            ...action.payload
        }),
        resetBoardCondition: () => initialState
    }
});

export const { saveBoardCondition, resetBoardCondition } = boardConditionSlice.actions;
export default boardConditionSlice.reducer;
