import { batch } from "react-redux";
import afterStepDataController from "../../controllers/afterStepDataController";
import stepDataController from "../../controllers/stepDataController";
import { getUrlParams, HIDDEN, VISIBILITY_CHANGE } from "../../helpers";
import { saveDealingSteps, resetDealingStepsState } from "../reducers/dealingStepsSlice";
import { saveBoardCondition, resetBoardCondition } from "../reducers/boardConditionSlice";
import { soundPlayByName } from "../../services/sounds";
import { resetGameDateState } from "../reducers/gameDataSlice";
import { resetGameDataReceived } from "../reducers/dataReceivedSlice";
import { resetDealingAnimationCountState } from "../reducers/dealingAnimationCountSlice";
import { saveUsersTimes } from "../reducers/usersTimesSlice";

const [getPendingStepStack, addPendingStepStack, clearPendingStepStack, removeFirstPendingStackCall] = (() => {
    let pendingStepStack = [];

    return [
        () => pendingStepStack,
        value => (pendingStepStack = [...pendingStepStack, value]),
        () => (pendingStepStack = []),
        () => pendingStepStack.shift()
    ];
})();

const handleVisibilityChange = () => !document[HIDDEN] && clearPendingStepStack();
window.addEventListener(VISIBILITY_CHANGE, handleVisibilityChange);
window.addEventListener("offline", handleVisibilityChange);

const animationMiddleware = ({ getState, dispatch }) => next => action => {
    const { isHistory } = getUrlParams();

    if (isHistory) {
        return next(action);
    }

    const {
        dealingStepsState: {
            steps: { step1, step2, step3, step4 }
        },
        settingsState: { isAnimationEnabled },
        dealingAnimationCountState: { dealingAnimationCount },
        boardConditionState: { isDeckDisappearing, showPlaceholder, isActivePlaceholder },
        gameDataState: { cardsToLastBeat, cardsToBoard, isDeckEmpty, newHandAfterStep, newRoundAfterStep }
    } = getState();

    const isStep = step1 || step2 || step3 || step4;

    const callPendingStepStack = firstCall => {
        //call all pending step stack
        const pendingStepStack = getPendingStepStack();
        if (pendingStepStack.length) {
            if (firstCall) {
                const stackFirstCall = pendingStepStack[0];
                stackFirstCall();
                removeFirstPendingStackCall();
            } else {
                pendingStepStack.forEach(pendingAction => next(pendingAction));
                clearPendingStepStack();
            }
        }
    };

    switch (action.type) {
        case "gameData/saveAfterStepData": {
            next(action);
            const pendingStepStack = getPendingStepStack();

            if (!isStep && pendingStepStack.length && !action.payload.showRoundResult && !newHandAfterStep) {
                if (pendingStepStack[0].payload.newData) {
                    dispatch(stepDataController(pendingStepStack[0].payload.newData));
                    clearPendingStepStack();
                } else {
                    callPendingStepStack();
                }
            } else if (pendingStepStack[0]?.type === "gameData/saveNewHand") {
                dispatch(stepDataController(pendingStepStack[0].payload.newData));
            }
            break;
        }

        case "boardConditionState/saveBoardCondition": {
            const pendingStepStack = getPendingStepStack();

            if (
                isAnimationEnabled &&
                (isStep ||
                    ((cardsToLastBeat.length || cardsToBoard.length) &&
                        !pendingStepStack[0]?.payload.newHandAfterStep &&
                        !pendingStepStack[0]?.payload.newRoundAfterStep))
            ) {
                break;
            } else {
                if (action.payload.showPlaceholder && !action.payload.isActivePlaceholder && !isActivePlaceholder) {
                    dispatch(saveBoardCondition({ showPlaceholder: true, isActivePlaceholder: true }));
                } else {
                    next(action);
                }
                break;
            }
        }

        case "gameData/excludeCardFromAnimations": {
            action.payload && next(action);

            if (
                action.payload &&
                (cardsToBoard.length === 1 || cardsToLastBeat.length === 1) &&
                [...cardsToBoard, ...cardsToLastBeat].includes(action.payload.cardId)
            ) {
                dispatch(afterStepDataController());
            } else if (!action.payload) {
                clearPendingStepStack();
                batch(() => {
                    next(resetGameDateState());
                    next(resetBoardCondition());
                    next(resetGameDataReceived());
                    next(resetDealingStepsState());
                    next(resetDealingAnimationCountState());
                });
            }

            break;
        }

        case "settingsState/saveSettings": {
            next(action);

            if (action.payload.isAnimationEnabled) {
                next(saveBoardCondition({ isDeckHidden: isDeckEmpty, isDeckDisappearing: false }));
            }
            break;
        }

        case "dealingStepsState/saveDealingSteps": {
            const pendingStepStack = getPendingStepStack();
            next(action);
            if (pendingStepStack.length && !Object.values(action.payload).some(i => i)) {
                setTimeout(() => dispatch(stepDataController(pendingStepStack[0].payload.newData)), 250);
                clearPendingStepStack();
            }
            break;
        }

        case "gameData/saveGameData": {
            if (showPlaceholder) {
                next(saveBoardCondition({ isActivePlaceholder: false, showPlaceholder: false }));
            }
            next(action);
            break;
        }

        case "dealingAnimationCount/decreaseDealingAnimationsCount": {
            next(action);
            if (dealingAnimationCount === 1) {
                batch(() => {
                    isDeckEmpty && dispatch(saveBoardCondition({ isDeckDisappearing: true }));
                    dispatch(saveDealingSteps({ step2: false, step3: false, step4: false }));
                });

                if (isDeckEmpty) {
                    setTimeout(() =>
                        dispatch(saveBoardCondition({ isDeckDisappearing: false, isDeckHidden: true }), 1000)
                    );
                }
            }

            break;
        }

        case "gameData/saveNewHand": {
            if (isAnimationEnabled && (cardsToLastBeat.length || cardsToBoard.length)) {
                addPendingStepStack(action);
            } else {
                soundPlayByName("throwCard");
                next(action);
            }
            break;
        }

        case "gameData/saveNewRound": {
            if (isAnimationEnabled && (cardsToLastBeat.length || cardsToBoard.length)) {
                addPendingStepStack(action);
            } else {
                if (isDeckDisappearing) {
                    dispatch(saveBoardCondition({ isDeckDisappearing: false }));
                }
                soundPlayByName("throwCard");
                next(action);
            }
            break;
        }

        case "gameData/saveNewRoundOrHandData": {
            if (newHandAfterStep) {
                soundPlayByName("playerDealing");
                if (isAnimationEnabled) {
                    setTimeout(() => soundPlayByName("otherDealing"), 670);
                }
            } else if (newRoundAfterStep) {
                if (!isAnimationEnabled) {
                    soundPlayByName("playerDealing");
                } else {
                    setTimeout(() => {
                        soundPlayByName("playerDealing");
                        setTimeout(() => {
                            soundPlayByName("otherDealing");
                            setTimeout(() => soundPlayByName("otherDealing"), 860);
                        }, 750);
                    }, 220);
                }
            }
            next(action);
            break;
        }

        case "gameData/saveStepData": {
            const pendingStepStack = getPendingStepStack();

            if (
                isAnimationEnabled &&
                (isStep ||
                    ((cardsToLastBeat.length || cardsToBoard.length) &&
                        !pendingStepStack[0]?.payload.newHandAfterStep &&
                        !pendingStepStack[0]?.payload.newRoundAfterStep))
            ) {
                addPendingStepStack(action);
            } else {
                next(action);
            }
            break;
        }

        case "dealingAnimationCount/setDealingAnimationsCountByAmount": {
            next(action);
            if (action.payload === 0) {
                batch(() => {
                    isDeckEmpty && dispatch(saveBoardCondition({ isDeckDisappearing: true }));
                    dispatch(saveDealingSteps({ step2: false, step3: false, step4: false }));
                });

                if (isDeckEmpty) {
                    setTimeout(() =>
                        dispatch(saveBoardCondition({ isDeckDisappearing: false, isDeckHidden: true }), 1000)
                    );
                }
            }

            break;
        }

        case "gameData/saveGameResultData": {
            next(action);
            const { player, opponent } = action?.payload || {};
            clearPendingStepStack();
            dispatch(
                saveUsersTimes({
                    player: { stepTime: player?.stepTime, gameTime: player?.gameTime },
                    opponent: { stepTime: opponent?.stepTime, gameTime: opponent?.gameTime }
                })
            );
            break;
        }

        default:
            next(action);
    }
};

export default animationMiddleware;
