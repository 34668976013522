import { detect } from "detect-browser";
const browser = detect();

const AppConstants = {
    network: {
        CURRENT_GAME_NAME: "pasoor",
        REACT_APP_URL: process.env.REACT_APP_URL,
        REACT_APP_IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
        REACT_APP_TRANSLATIONS_URL: process.env.REACT_APP_TRANSLATIONS_URL,
        network_request_url: {
            API: "api",
            DEMO: "Demo",
            PASOOR: "game",
            RULES: "rules",
            HISTORY: "History",
            GET_RULE: "getrule",
            SETTINGS: "Settings",
            SWITCH_BOT: "SwitchBot",
            GET_HISTORY: "GetHistory",
            GET_SETTINGS: "GetSettings",
            GET_TRANSLATIONS: "GetTranslations"
        },
        socket: {
            GET_INITIAL_STATE: "GetInitialState",
            INITIAL_STATE: "InitialState",
            GET_GAME_DATA: "getGameData",
            GAME_DATA: "GameData",
            HIT_ME: "HitMe",
            MOVE: "Move",
            STEP: "Step",
            STAND: "Stand",
            BLIND_CARD: "BlindCard",
            DOUBLE_STAKE: "DoubleStake",
            DOUBLE_STAKE_REQUEST: "DoubleStakeRequest",
            ROUND_RESULT: "RoundResult",
            CONNECTION_CHANGED: "ConnectionChanged",
            NEW_ROUND: "NewRound",
            LEAVE_GAME: "LeaveGame",
            DOUBLE_STAKE_RESPONSE: "DoubleStakeResponse",
            GAME_RESULT: "GameResult",
            GET_INET_SPEED: "GetInetSpeed",
            INET_SPEED: "InetSpeed",
            BOT_SWITCHED: "BotSwitched",
            SWITCH_BOT: "SwitchBot"
        },
        socketQueries: {
            doubleStakeResponse: {
                double: 1,
                accept: 2,
                redouble: 3,
                surrender: 4
            }
        }
    },
    dimensions: {
        DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP: 16,
        DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP: 9,
        DEFAULT_ASPECT_RATIO_WIDTH_MOBILE: 19.5,
        DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE: 9,
        FONT_SIZE_SCALE_INDEX_DESKTOP: 1280,
        FONT_SIZE_SCALE_INDEX_LANDSCAPE: 812,
        FONT_SIZE_SCALE_INDEX_PORTRAIT: 375
    },
    devicesMode: {
        DESKTOP: "desktop",
        MOBILE: "mobile"
    },
    popupTypes: {
        SETTINGS: "settings",
        DOUBLE_STAKE_CONFIRMATION: "doubleStakeConfirmation",
        DOUBLE_STAKE: "doubleStake",
        LEAVE_GAME: "leaveGame",
        GAME_OVER: "gameOver",
        REVANCHE_OFFER: "revancheOffer",
        LOST_CONNECTION: "lostConnection"
    },
    cardAnimationConfig: {
        STAND_ANIMATION_COUNT_PER_CARD: 2,
        FLIPPED_ANIMATION_COUNT_PER_CARD: 1
    },
    cardValues: {
        JACK_VALUE: 11,
        KING_VALUE: 13,
        QUEEN_VALUE: 12
    },
    cardViewTypesByThemeIds: {
        1: "c",
        2: "d",
        3: "e"
    }
};

export default AppConstants;

export const BROWSER_NAME = browser?.name;
export const IS_IOS = (() => /iPad|iPhone|iPod/.test(navigator.userAgent))();
export const IS_IOS_CHROME = IS_IOS && ["chrome", "crios"].includes(BROWSER_NAME);

export const GAME_DATA_KEYS = {
    od: "opponentRoundResultData",
    ois: "opponentInitialState",
    pd: "playerRoundResultData",
    pis: "playerInitialState",
    msd: "maxStakeDoubling",
    gc: "gameConfiguration",
    og: "opponentGameScore",
    ot: "player2TrumpCard",
    pt: "player1TrumpCard",
    im: "isMaxDoubleStake",
    pg: "playerGameScore",
    dd: "doubleStakeData",
    igs: "isGameStarted",
    nn: "playerNickName",
    ti: "tournamentInfo",
    ip: "isPlayerPlayed",
    d: "isPlayerDealer",
    oc: "opponentCards",
    cs: "currentStake",
    ipw: "isPlayerWin",
    rs: "roundsScores",
    td: "tournamentId",
    w: "opponentScore",
    iw: "isPlayerWin",
    ds: "doubleStake",
    rr: "roundResult",
    ic: "isConnected",
    n: "notification",
    t: "isPlayerTurn",
    b: "isPlayerBeat",
    f: "finishStatus",
    pc: "playerCards",
    is: "isSwitched",
    gp: "gamePoints",
    bc: "boardCards",
    e: "isDeckEmpty",
    v: "playerScore",
    gs: "gameScore",
    in: "incognito",
    pf: "prizeFund",
    to: "tourOrder",
    r: "roundScore",
    gt: "gameTime",
    lb: "lastBeat",
    sp: "stepTime",
    nr: "newRound",
    pr: "private",
    ws: "withSur",
    ig: "isGuest",
    g: "gameTime",
    i: "isPlayer",
    s: "stepTime",
    o: "opponent",
    id: "isDemo",
    pp: "popups",
    h: "newHand",
    ci: "cardId",
    p: "player",
    x: "score",
    y: "clubs",
    c: "cards",
    z: "soor",
    m: "move"
};

export const VALIDATION_COOKIE_NAME = "_ga_anl_cf3q";
