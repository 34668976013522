import { configureStore } from "@reduxjs/toolkit";
import animationMiddleware from "./middleware/animationMiddleware";

import historyState from "./reducers/historySlice";
import demoGameState from "./reducers/demoGameSlice";
import settingsState from "./reducers/settingsSlice";
import gameDataState from "./reducers/gameDataSlice";
import initialState from "./reducers/initialStateSlice";
import connectionState from "./reducers/connectionSlice";
import usersTimesState from "./reducers/usersTimesSlice";
import popupTypesState from "./reducers/popupTypesSlice";
import translationState from "./reducers/translationSlice";
import dataReceivedState from "./reducers/dataReceivedSlice";
import dealingStepsState from "./reducers/dealingStepsSlice";
import lowConnectionState from "./reducers/lowConnectionSlice";
import boardConditionState from "./reducers/boardConditionSlice";
import dealingAnimationCountState from "./reducers/dealingAnimationCountSlice";

export const reducer = {
    historyState,
    initialState,
    demoGameState,
    settingsState,
    gameDataState,
    popupTypesState,
    connectionState,
    usersTimesState,
    translationState,
    dealingStepsState,
    dataReceivedState,
    lowConnectionState,
    boardConditionState,
    dealingAnimationCountState
};

export const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(animationMiddleware)
});
